// src/store/store.ts
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage

// Define a type for each item in the basket
export interface BasketItem {
  id: string;
  imageUrl: string;
  title: string;
  description: string;
  price: number;
  firstName: string;
  lastName: string;
  date: string;
}

// Define a type for the initial state of the basket
interface BasketState {
  items: BasketItem[];
}

const initialState: BasketState = {
  items: JSON.parse(localStorage.getItem('basketItems') || '[]'),
};


const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addToBasket: (state, action: PayloadAction<BasketItem>) => {
      state.items.push(action.payload);
    },
    removeFromBasket: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(item => item.id !== action.payload);
    },
    clearBasket: (state) => {
      state.items = [];  // Clear all items from the basket
    },
  },
});

const persistConfig = {
  key: 'basket',
  storage,
};

const persistedReducer = persistReducer(persistConfig, basketSlice.reducer);

const store = configureStore({
  reducer: {
    basket: persistedReducer, // Use persisted reducer for basket
  },
});

const persistor = persistStore(store); // To manage persistence

export const { addToBasket, removeFromBasket, clearBasket} = basketSlice.actions;

// Define RootState and AppDispatch types based on the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
