import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GiftVoucherScreen, HomeScreen, DownloadVoucherScreen} from './screens';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/gift-vouchers" element={<GiftVoucherScreen/>} />
                <Route path="/download" element={<DownloadVoucherScreen />} />
                {/* 
                <Route path="/terms-and-conditions" element={<TermsAndConditionsScreen />} />
                <Route path="*" element={<NotFoundScreen />} />  */}
              </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
