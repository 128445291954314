import { useDispatch } from "react-redux";
import { Navbar, Footer } from "../../components";
import GiftVoucherItem from "../../components/GiftVoucherItem";
import { useEffect } from "react";
import { clearBasket } from "../../store/store";

export const GiftVoucherScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearBasket());
  }, [dispatch]);
  
  const vouchers = [
    {
      id: '15min',
      imageUrl: require('../../assets/images/15MinVoucherImage.jpg'),
      title: "15-Minute Flying Experience",
      description: "15 Minutes of flying",
      price: 45,
    },
    {
      id: '30min',
      imageUrl: require('../../assets/images/30MinVoucherImage.jpg'),
      title: "30-Minute Flying Experience",
      description: "30 Minutes of flying",
      price: 85,
    },
    {
      id: '60min',
      imageUrl: require('../../assets/images/60MinVoucherImage.jpg'),
      title: "60-Minute Flying Experience",
      description: "Experience the thrill of flight over your favorite places.",
      price: 135,
    },
  ];

  return (
    <>
      <div className="font-inter antialiased bg-light bg-white text-black tracking-tight">
        <div className="flex flex-col min-h-screen overflow-hidden">
          <Navbar />

          {/* Hero section */}
          <section className="relative w-full h-screen flex items-center justify-center text-center text-white">
            <div className="absolute inset-0 h-full w-full">
              <img
                src={require('../../assets/images/gift-voucher-background.png')}
                alt="Hero background"
                className="w-full h-full object-cover"
              />
            </div>

            <div className="relative z-10 max-w-4xl px-4 lg:mt-[550px]">
              <h1
                className="text-5xl md:text-7xl font-bold tracking-tight leading-tight mb-4"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                Gift Vouchers
              </h1>
              <h2
                className="text-2xl md:text-3xl font-extralight tracking-tight leading-tight mb-5"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                Give the gift of flight this Christmas
              </h2>
            </div>
          </section>

          {/* Voucher information section */}
          <section className="mt-20">
            <div className="container mx-auto px-4 pb-[35px]">
              <h1 className="text-center font-bold text-5xl mb-5">Unforgettable Flight Experience</h1>
              <p className="text-lg my-4">
                At Hilltop Flying Club in Sixmilecross, we provide the chance to give an unforgettable gift with our flight experience vouchers. Perfect for anyone who's ever dreamed of flying, these vouchers make for a unique and memorable present.
              </p>
              <p className="text-lg">Soar over the breathtaking hills of Sixmilecross, taking in stunning views that only an airborne perspective can provide. With our microlight planes, you’ll share the cockpit with a fully trained pilot.</p>
            </div>
          </section>

          {/* Gift Vouchers Grid */}
          <section>
            <div className="container mx-auto px-4 pb-5">
              <div className="grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
                {vouchers.map((voucher, index) => (
                  <GiftVoucherItem
                    key={index}
                    imageUrl={voucher.imageUrl}
                    title={voucher.title}
                    description={voucher.description}
                    price={voucher.price}
                    id={voucher.id}
                  />
                ))}
              </div>
            </div>
          </section>
          <p className=" px-6 text-center text-sm font-bold text-gray-900">Please note that Vouchers are non-refundable and valid for 12 months from date of purchase</p>

          {/* Contact Information */}
          <section>
            <div className="container mx-auto px-4 pb-40 pt-10 text-center">
              <h1 className="text-[30px] md:text-[40px] sm:text-center">Find out more by calling us today on 0778 756 0005 or 02880757733</h1>
            </div>
          </section>
          
          <Footer />
        </div>
      </div>
    </>
  );
};

export default GiftVoucherScreen;
