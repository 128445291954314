import { useEffect } from "react";
import { Navbar, Footer } from "../../components";
import { FaEnvelope, FaMapPin, FaPhone} from 'react-icons/fa';
import { clearBasket } from "../../store/store";
import { useDispatch } from "react-redux";

export const HomeScreen = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(clearBasket());
      }, [dispatch]);
    
    return(
        <>
            <div className="font-inter antialiased bg-light bg-white text-black tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <Navbar />
                    <section className="relative w-full h-screen flex items-center justify-center text-center text-white">
                        {/* Background image */}
                        <div className="absolute inset-0 h-full w-full">
                            <img 
                                src={require('../../assets/images/hero-background.png')} 
                                alt="Hero background" 
                                className="w-full h-full object-cover"
                            />
                        </div>

                        {/* Hero content */}
                        <div className="relative z-10 max-w-4xl px-2 lg:mt-[550px]">
                            <h1 
                                className="text-5xl md:text-7xl font-bold tracking-tight leading-tight mb-4"
                                data-aos="fade-down"
                                data-aos-delay="300"
                            >
                              Hilltop Flying Club
                            </h1>
                            <h2 
                                className="text-2xl md:text-3xl font-extralight tracking-tight leading-tight mb-5"
                                data-aos="fade-down"
                                data-aos-delay="300"
                            >
                              Learn to fly with us
                            </h2>
                            <div className="mt-[40px] mb-[40px]">
                                <a href="/gift-vouchers" className="bg-white py-5 px-5 rounded-full hover:bg-gray-100">
                                    <span className="text-black ">Flying Experiences</span>
                                </a>
                            </div>
                        </div>
                    </section>
                    <section id="about" className="py-16 bg-white">
                        <div id="aboutUs" className="max-w-6xl mx-auto px-4 sm:px-6">
                            <div className="flex flex-col md:flex-row items-center mb-16">
                                <div className="md:w-1/2">
                                    <img src={require('../../assets/images/about-image.png')} alt="About" className="w-full h-auto object-cover rounded-lg " />
                                </div>
                                <div className="md:w-3/4 md:pl-8 mt-8 md:mt-0">
                                    <h2 className="text-6xl font-bold mb-4">About Us</h2>
                                    <p className="text-lg text-gray-700">
                                        Located in the heart of Sixmilecross, Hilltop Flying Club is all about sharing our passion for flight. We love nothing more than seeing our customers take to the skies and experience the thrill of flying.
                                    </p>
                                    <p className="text-lg text-gray-700 my-5">
                                        Whether you're here to learn how to pilot a microlight or simply want to enjoy the breathtaking views on a test flight, our experienced team is dedicated to making every journey memorable.
                                    </p>
                                    <p className="text-lg text-gray-700">
                                        At Hilltop, we don't just teach you to fly; we bring you on an adventure you'll never forget. Join us and let’s explore the skies together!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="vouchers" className="py-16 bg-white">
                        <div id="vouchers" className="max-w-6xl mx-auto px-4 sm:px-6">
                            <div className="flex flex-col md:flex-row mb-16">
                                <div className="md:w-3/4 md:pl-8 mt-8 md:mt-0">
                                    <h2 className="text-6xl font-bold mb-4">Give the Gift of Flight with Our Vouchers</h2>
                                    <p className="text-lg text-gray-700">
                                        Trust in the expertise behind our flying and trial lessons to deliver an unforgettable experience. Our gift vouchers offer a unique opportunity to surprise a loved one with the thrill of flight. Our vouchers make the perfect gift for any adventure enthusiast.
                                    </p>
                                    <div className="mt-[40px] mb-[40px]">
                                        <a href="/gift-vouchers" className="bg-black py-5 px-5 rounded-full hover:bg-gray-800">
                                            <span className="text-white">Explore Vouchers</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="md:w-1/2">
                                    <img src={require('../../assets/images/voucher-background.png')} alt="Voucher bg" className="w-full h-auto object-cover rounded-lg " />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container mx-auto px-4 pb-40 pt-4">
                            <h1 className="text-[30px] md:text-[40px] sm:text-center">Find out more by calling us today on 0778 756 0005 or 02880757733</h1>
                        </div>
                    </section>

                    <section id="contact" className="py-16 bg-gray-900">
                        <div className="container mx-auto px-4">
                            <div className="flex flex-col md:flex-row justify-between items-center text-white space-y-8 md:space-y-0">
                            
                            {/* Email */}
                            <div className="flex flex-col items-center text-center">
                                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center my-4">
                                <FaEnvelope className="text-3xl text-gray-800" />
                                </div>
                                <a
                                className="text-gray-200 hover:text-blue-500 transition duration-150 ease-in-out text-sm md:text-base"
                                href="mailto:hello@tokyodigital.com.au"
                                >
                                hello@hiltopflyingclub.com
                                </a>
                            </div>

                            {/* Location */}
                            <div className="flex flex-col items-center text-center">
                                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center my-4">
                                <FaMapPin className="text-3xl text-gray-800" />
                                </div>
                                <p className="text-gray-200 text-sm md:text-base">Hilltop Flying Club, Altamuskin, Omagh BT79 9JA</p>
                            </div>

                            {/* Phone Number */}
                            <div className="flex flex-col items-center text-center">
                                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center my-4">
                                <FaPhone className="text-3xl text-gray-800" />
                                </div>
                                <a
                                    className="text-gray-200 hover:text-blue-500 transition duration-150 ease-in-out text-sm md:text-base"
                                    href="tel:+447787560005"
                                >
                                    0778 756 0005 
                                </a>
                                or 
                                <a
                                    className="text-gray-200 hover:text-blue-500 transition duration-150 ease-in-out text-sm md:text-base"
                                    href="tel:+4402880757733"
                                >
                                    02880757733
                                </a>
                            </div>

                            </div>
                        </div>
                    </section>


                    {/* {cartItems.length > 0 && (
                        <div className="fixed bottom-0 w-full sm:hidden bg-white py-5 pb-10 px-6 shadow-lg z-20">
                            <Link 
                            to="/cart"
                            className="bg-black text-white py-3 px-6 rounded-full flex items-center space-x-2 justify-center"
                            aria-label="Go to Cart"
                            >
                            <span>{cartItems.length}</span>
                            <FaShoppingCart className="text-white" />
                            <span className="text-white">Go to Basket</span>
                            </Link>
                        </div>
                    )} */}
                    
                    {/* Footer */}
                    <Footer />

                </div>

            </div> 
        </>
    )

}