/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import { FaEnvelope, FaFacebookF, FaMapPin, FaPhone } from 'react-icons/fa';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-white text-white py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
                <img
                    src={require('../../assets/images/logo.png')}
                    alt="Logo"
                    className="w-[80px] h-auto mb-4"
                />
                {/* Top area: Blocks */}
                <div className="grid grid-cols-1 ml-5 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-20 mb-8 md:mb-12">

                    {/* Logo Block */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Contact Us</h6>
                        <ul className="space-y-1">
                            <li>
                                <a
                                    className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out"
                                    href="tel:+447787560005"
                                >
                                    <FaPhone className="mr-2" /> +44 7787 560005
                                </a>
                            </li>
                            <li>
                                <a
                                    className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out"
                                    href="mailto:hello@tokyodigital.com.au"
                                >
                                    <FaEnvelope className="mr-2" /> hello@hiltopflyingclub.com
                                </a>
                            </li>
                            <li>
                                <a className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out">
                                    <FaMapPin className="mr-2" /> Six Mile Cross, United Kingdom, BT79 9JA
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Site Links */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Site</h6>
                        <ul className="space-y-1">
                            <li>
                                <Link className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out" to="/">Home</Link>
                            </li>
                            <li>
                                <Link className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out" to="/#about">About Us</Link>
                            </li>
                            <li>
                                <Link className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out" to="/#contact">Contact</Link>
                            </li>
                            <li>
                                <Link className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out" to="/gift-vouchers">Gift Vouchers</Link>
                            </li>
                        </ul>
                    </div>

                    {/* Social Links */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Socials</h6>
                        <ul className="space-y-1">
                            <li>
                                <a
                                    className="flex items-center text-gray-800 hover:text-gray-500 transition duration-150 ease-in-out"
                                    href="https://www.facebook.com/hilltopflyingclub"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaFacebookF className="mr-2" /> Facebook
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Footer Bottom Section */}
                <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-400">
                    <p className="mb-2 md:mb-0">&copy; {currentYear} Hilltop Flying Club. All rights reserved.</p>
                    <p>
                        Website made by <a className="text-gray-400 hover:text-red-300" href="https://www.tokyodigital.com.au" target="_blank" rel="noopener noreferrer">Tokyo Digital</a>
                    </p>
                </div>
            </div>
        </footer>
    );
};
