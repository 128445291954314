import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromBasket, RootState } from '../../store/store';
import { addToBasket } from '../../store/store';

interface GiftVoucherItemProps {
  id: string;
  imageUrl: string;
  title: string;
  description: string;
  price: number;
}

const GiftVoucherItem: React.FC<GiftVoucherItemProps> = ({ id, imageUrl, title, description, price }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showStripeButton, setShowStripeButton] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const basketItems = useSelector((state: RootState) => state.basket.items);

  // Allow only one item in the basket
  const isBasketFull = basketItems.length > 0;
  const isInBasket = basketItems.some((item) => item.id === id);

  const handleAddToBasketClick = () => {
    if (!isBasketFull) {
      setIsModalOpen(true); // Open the modal when adding an item
    }
  };

  // Determine the type of show duration (15min, 30min, or 60min)
  const has15MinShow = id === "15min";
  const has30MinShow = id === "30min";
  const has60MinShow = id === "60min";

  const handleContinueButton = () => {
    setShowStripeButton(true);
    const item = { id, imageUrl, title, description, price, firstName, lastName, date: new Date().toLocaleDateString()}; // Include name in item
    dispatch(addToBasket(item));
    setFirstName('');
    setLastName('');
  };

  const handleOnClose = () => {
    setFirstName('');
    setLastName('');
    setShowStripeButton(false);
    setIsModalOpen(false);
    dispatch(removeFromBasket(id));
  }

  return (
    <div className="border border-gray-200 rounded-lg p-4 w-full text-center shadow-md">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-80 object-cover rounded-md mb-4"
      />
      <h2 className="text-lg font-semibold mb-2">{title}</h2>
      <p className="text-sm text-gray-600 mb-2">{description}</p>
      <p className="text-lg font-bold text-gray-800 mb-4">£{price}</p>
      <button
        onClick={handleAddToBasketClick}
        className={`w-full py-2 rounded-md text-white transition-colors ${
          isBasketFull || isInBasket ? 'bg-gray-500 cursor-not-allowed' : 'bg-gray-900 hover:bg-gray-700'
        }`}
        disabled={isBasketFull || isInBasket} // Disables button if basket is full or item is already in basket
      >
        {isBasketFull ? 'Only one item allowed in cart' : isInBasket ? 'Already added to cart' : 'Add to Basket'}
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full sm:max-w-lg smh-full">
            
            {/* Conditionally render inputs based on showStripeButton state */}
            {!showStripeButton && (
              <>
                <h2 className="text-xl font-semibold mb-4">Enter name for voucher</h2>
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  required
                  className="border border-gray-300 rounded-md w-full px-4 py-2 mb-2"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  required
                  className="border border-gray-300 rounded-md w-full px-4 py-2 mb-2"
                />
              </>
            )}

            {showStripeButton && (
              <div>
                <h2 className="text-xl font-semibold mb-4">Purchase Voucher</h2>
                {has15MinShow && (
                  <stripe-buy-button
                    buy-button-id="buy_btn_1QNEPqF05nNuvZjbBdl6H6xs"
                    publishable-key="pk_live_51QKVJTF05nNuvZjbB9MD66WULXDYPAyWsJIR7g9w7b7PHoqx7pinAxdeQ0NMiz4SCz1dKbnEoOPW118sW9sVYXFD00jFqh6WWW"
                  />
                )}
                {has30MinShow && (
                  <stripe-buy-button
                    buy-button-id="buy_btn_1QNEOdF05nNuvZjbpwZj131I"
                    publishable-key="pk_live_51QKVJTF05nNuvZjbB9MD66WULXDYPAyWsJIR7g9w7b7PHoqx7pinAxdeQ0NMiz4SCz1dKbnEoOPW118sW9sVYXFD00jFqh6WWW"
                  />
                )}
                {has60MinShow && (
                  <stripe-buy-button
                    buy-button-id="buy_btn_1QNEMdF05nNuvZjbmcESccLO"
                    publishable-key="pk_live_51QKVJTF05nNuvZjbB9MD66WULXDYPAyWsJIR7g9w7b7PHoqx7pinAxdeQ0NMiz4SCz1dKbnEoOPW118sW9sVYXFD00jFqh6WWW"
                  />
                )}
              </div>
            )}

            <div className="flex justify-end space-x-4">
              <button
                onClick={handleOnClose}
                className="bg-gray-300 px-4 py-2 rounded-md text-gray-700"
              >
                Cancel
              </button>
              {!showStripeButton && (
                <button
                  onClick={handleContinueButton}
                  className={`px-4 py-2 rounded-md ${
                    firstName.trim() === "" || lastName.trim() === ""
                      ? "bg-gray-400 text-gray-500 cursor-not-allowed"
                      : "bg-gray-900 text-white"
                  }`}
                  disabled={firstName.trim() === "" || lastName.trim() === ""}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiftVoucherItem;
