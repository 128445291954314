import React, { useEffect } from "react";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { BasketItem, RootState, clearBasket} from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import { Footer, Navbar } from "../../components";
import { FaDownload } from "react-icons/fa";

export const DownloadVoucherScreen = () => {
    const basketItems = useSelector((state: RootState) => state.basket.items);
    const dispatch = useDispatch();  // Initialize dispatch function
    const navigate = useNavigate();  // Initialize the navigate function

    useEffect(() => {
        // Redirect to home if the basket is empty
        if (basketItems.length === 0) {
            navigate("/");  // Redirect to homepage
        }
    }, [basketItems.length, navigate]);

    const handleGeneratePDF = async (item: BasketItem) => {
        const name = `${item.firstName} ${item.lastName}`;
        const dateIssued = item.date;
        const authorizedBy = "Online Purchase";
        
        // Generate a unique 5-digit voucher number from the timestamp
        const timestamp = Date.now();
        const voucherNo = (timestamp % 100000).toString().padStart(5, '0'); // Get the last 5 digits
    
        let voucherTemplate;
        if (item.id === '15min') {
            voucherTemplate = require('../../assets/pdfs/Voucher-15minute.pdf');
        } else if (item.id === '30min') {
            voucherTemplate = require('../../assets/pdfs/Voucher-30minute.pdf');
        } else if (item.id === '60min') {
            voucherTemplate = require('../../assets/pdfs/Voucher-60minute.pdf');
        } else {
            console.error("Invalid item ID");
            return;
        }
    
        try {
            const existingPdfBytes = await fetch(voucherTemplate).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
            // const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            const fontSize = 12;
    
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
    
            // Fill in the fields on the template
            firstPage.drawText(name, { x: 50, y: 145, size: fontSize, font, color: rgb(0, 0, 0) });
            firstPage.drawText(dateIssued, { x: 50, y: 97, size: fontSize, font, color: rgb(0, 0, 0) });
            firstPage.drawText(authorizedBy, { x: 50, y: 58, size: fontSize, font, color: rgb(0, 0, 0) });
            firstPage.drawText(voucherNo, { x: 50, y: 20, size: fontSize, font: font, color: rgb(0, 0, 0) });
    
            // Additional placements if needed for voucherNo, dateIssued, authorizedBy
            firstPage.drawText(dateIssued, { x: 410, y: 21, size: fontSize, font, color: rgb(0, 0, 0) });
            firstPage.drawText(authorizedBy, { x: 220, y: 21, size: fontSize, font, color: rgb(0, 0, 0) });
            firstPage.drawText(voucherNo, { x: 520, y: 21, size: fontSize, font: font, color: rgb(0, 0, 0) });
    
            // Save the PDF and prepare the download
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
    
            // Create a hidden link and trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${name}-${item.id}-voucher.pdf`; // Set filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up the DOM
    
            setTimeout(() => {
                dispatch(clearBasket());  // Clear the basket after the voucher is downloaded
            }, 60000);  // Delay to allow for download to start before refreshing
        } catch (error) {
            console.error("Failed to generate PDF:", error);
        }
    };
    

    return (
        <div className="flex flex-col min-h-screen justify-between">
            <Navbar />
            <div className="flex flex-col items-center justify-center flex-grow mt-6">
                <h2 className="text-2xl font-semibold mb-4">Download Your Voucher</h2>
                <p className="text-red-500 text-center my-3 text-sm">
                    Ensure you save your voucher in a safe place, as it will no longer be accessible after download.
                </p>

                <div className="space-y-2">
                    {basketItems.map((item) => (
                        <button
                            key={item.id}
                            onClick={() => handleGeneratePDF(item)}
                            className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-medium py-3 px-5 rounded-lg w-full md:w-auto transition duration-300 ease-in-out"
                            aria-label="Download Flying Gift Voucher"
                        >
                            <FaDownload className="mr-2" /> 
                            Download Gift Voucher
                        </button>
                    ))}
                </div>

                <p className="text-center text-sm text-gray-900 my-5 font-bold">
                    Vouchers are non-refundable and valid for 12 months from the date of purchase.
                </p>
            </div>
            <Footer />
        </div>
    );
};
